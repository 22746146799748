.contact {
    background-color: #123456;
    height: 100vh;
}

.contactPageTitle {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    padding-top: 120px;
}

.contactForm * {
    margin-bottom: 10px;
    text-align: left;
    justify-content: left;
}

.contactForm p {
    font-size: 1.2rem;
}

.contactBody {
    padding: 0% 10%;
    padding-bottom: 5%;
    color: #eeeeee;
}

.contactBodyText {
    font-size: 1.2rem;
}

#contactSubmitButton {
    background-color: #eaa00b;
    border: #eaa00b;
}