#dropdown-label {
    background-color: #FFFFFF;
    border: none;
    color: #274c77;
}

#filter-bar {
    background-color: #FFFFFF;
    border: none;
    color: #274c77;
    margin: 0%;
}

.filterbar-container {
    margin: 10px;
}

#filterbar {
    margin: 0.5rem 0rem;
}

.filterbar-button {
    width: fit-content;
    margin: auto;
    background-color: #FFFFFF;
    color: #686868;
    font-weight: 600;
    border: 0.2rem solid #686868;
    padding: 5px 20px;
    margin-top: 10px;
}

.filterbar-menu-title {
    margin: 10px;
    font-size: 1.2rem;
    font-weight: 800;
}

.filterbar-option {
    padding: 10px 50px;
    margin: 10px 0px;
}

.filterbar-option:hover {
    cursor: pointer;
    color: #FFFFFF;
    background-color: #686868;
}