.admin-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0%;
}

.admin-section-label {
    margin: 1rem 0rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #274c77;
}

.admin-logout-div {
    text-align: right;
    padding-right: 1%;
}

#admin-logout-button {
    color: #274c77;
    margin: 0%;
    font-size: 0.6rem;
}

.editabout-subsection {
    text-align: left;
    padding: 0% 10%;
}

.editabout-subsection p {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: #274c77;
}

.editabout-input {
    width: 100%;
}

#edit-save-button {
    margin-top: 2rem;
    border: none;
    outline: none;
    background-color: #eaa80d;
}

#editfaq-accordion {
    background-color: #e9e9e9;
    margin: 1rem;
    text-align: left;
}

.editfaq-input {
    background-color: #e9e9e9;
    border: none;
    outline: #afafaf;
    width: 100%;
}

.editfaq-input-label {
    margin-top: 1rem;
}

.editfaq-delete-div {
    margin-top: 1rem;
    text-align: right;
}

.editfaq-delete-icon:hover {
    cursor: pointer;
}

.editfaq-newfaq-div {
    text-align: right;
    padding-right: 1%;
}

#editfaq-newfaq-button {
    color: #274c77;
}

.editfaq-new-form {
    position: absolute;
    z-index: 1;
    background-color: #e9e9e9;
    border-radius: 1rem;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
    text-align: left;
    padding: 1rem;
    width: 80%;
    left: 10%;
    box-shadow: 0.2rem 0.3rem #9e9e9e50;
}

.editfaq-new-form textarea {
    width: 100%;
}

.editfaq-new-button-div {
    text-align: center;
}

.editfaq-new-button-div button {
    margin: 0% 1%;
}

.editproduct-add-product {
    text-align: right;
}

.editproduct-modal-header {
    font-weight: 800;
}

.editproduct-modal-body-label {
    display: inline-block;
    width: 25%;
    font-weight: 600;
}

.editproduct-modal-body-input {
    display: inline-block;
    width: 75%;
}

.editproduct-category-label {
    margin: 5px;
    margin-top: 20px;
    text-align: left;
    font-size: 1rem;
    font-weight: 800;
}

.editproduct-field-group {
    display: inline-block;
    width: 100%;
    margin: 2px;
    text-align: left;
}

.editproduct-field-group p {
    margin-bottom: 0%;
    font-weight: 600;
}

.editproduct-field-input {
    width: 100%;
}

.editproduct-field-button-group {
    text-align: end;
}

.editproduct-field-button {
    margin: 10px;
    margin-right: 0px;
}