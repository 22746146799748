.aboutContent {
    padding-top: 30px;
}

.aboutSection {
    margin: 30px 0px;
}

.aboutPic {
    width: 100%;
}

.aboutSectionDescription {
    padding: 30px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #5E5E5E;
}

.aboutSectionDescription p {
    text-align: center;
}

.aboutSectionDescription ul {
    text-align: left;
}

.aboutSectionDescription ul li {
    margin-bottom: 10px;
}

.aboutPageTitle {
    font-size: 40px;
    font-weight: 700;
    color: #8D959E;
}

.contactBody {
    padding-top: 5%;
    line-height: 200%;
}