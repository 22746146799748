.cardGroup {
    padding: 0%;
    padding-top: 100px;
}

.cardRow {
    margin: auto;
    width: 100%;
}

.cardCol {
    padding: 1% 2%;
}

.card {
    padding: 2%;
    height: 400px;
}

.card:hover {
    cursor: pointer;
}

.product-page-title {
    margin-bottom: 0%;
}