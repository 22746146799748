.footer {
    padding: 10px;
    background-color: #EEEEEE;
}

.footerText {
    margin: 0px;
    color: #274C77;
    font-size: 0.8rem;
    font-weight: 500;
}