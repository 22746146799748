.home {
    height: 100vh;
    background: url("../../img/boat.jpg");
    background-size: cover;
}

.homeContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    justify-content: center;
    align-content: center;
}

.homeTitle {
    font-size: 250%;
    font-weight: bold;
    text-shadow: -3px -3px 0 #FFF;
    color: #003249;
    padding: 0% 2% 10% 2%;
}

.buttonGroup {
    align-items: center;
    margin-bottom: 15px;
}

.button {
    width: fit-content;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    background-color: #ffb703;
    border: #ffb703;
}

.button:hover {
    background-color: #ffb703;
}

.contact-button {
    width: fit-content;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    color: #003249;
    background-color: #eeeeee;
    border: #eeeeee;
}

.contact-button:hover {
    background-color: #eeeeee;
}