.product-details {
    padding-top: 100px;
}

.product-details-container {
    margin: 5% 0%;
}

.product-back-icon:hover {
    cursor: pointer;
}

.product-back-icon-container {
    text-align: left;
    padding: 0% 2.5%;
}

.product-pic {
    width: 90%;
    margin: auto;
}

.product-info-container {
    padding: 2% 5%;
    text-align: left;
}

.product-info-label {
    font-size: 1.2rem;
}

.product-info-text {
    font-size: 1rem;
}

.product-details-add-to-cart-button {
    outline: none;
    border: none;
    background-color: #274c77;
    min-width: 7rem;
    padding: 0.3rem;
    color: #FFFFFF;
    margin-top: 1rem;
}